
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

const ListWrapper = ({ title, titleSupplement, link, children }) => (
  <div className='px-3 py-3 py-sm-4'>
    <div className='px-2 py-3'>
      <div className={`${styles.titleLine} pl-2 pl-sm-3`}>
        {
          link
            ? <Link className={`${styles.title} pr-2`} to={link}>{title}</Link>
            : <div className={`${styles.title} pr-2`}>{title}</div>
        }
        <div>{titleSupplement}</div>
      </div>
    </div>
    <div className='px-2 pb-3'>
      {children}
    </div>
  </div>
)

export default ListWrapper
