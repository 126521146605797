
import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import BoardList from 'components/BoardList'
import NotFound from 'components/NotFound'
import Spinner from 'components/Spinner'
import { getBoardList } from 'redux/Actions/BoardList'
import { FETCHING_STATUS } from 'utils'

// there have been BoardList Component
class BoardListPage extends React.Component {
  componentDidMount () {
    const groupName = this.props.match.params.board_group

    this.props.getBoardList({
      name: groupName || ''
    })
  }

  componentDidUpdate (prevProps) {
    const groupName = this.props.match.params.board_group
    const prevGroupName = prevProps.match.params.board_group

    if (groupName !== prevGroupName) {
      this.props.getBoardList({
        name: groupName || ''
      })
    }
  }

  render () {
    const { boardList, status } = this.props
    const groupName = this.props.match.params.board_group
    const title = groupName || '看板列表'
    const notFound = (status === FETCHING_STATUS.FAIL || boardList.status <= 0)
    const isFetching = (status === FETCHING_STATUS.FETCHING)

    return (
      <Layout documentTitle={`${title} - BFPTT`}>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div
              className='col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 px-0'
              style={{ position: 'initial' }}
            >
              {
                notFound
                  ? <NotFound />
                  : (
                    <Spinner isFetching={isFetching} init>
                      <BoardList title={title} boards={boardList.children} />
                    </Spinner>
                  )
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  boardList: state.boardList.index.data,
  status: state.boardList.index.status
})
const mapDispatchToProps = (dispatch) => ({
  getBoardList: (payload) => dispatch(getBoardList(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(BoardListPage)
