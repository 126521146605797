import React from 'react'
import Layout from 'components/Layout'
import NotFound from 'components/NotFound'
import styles from './style.module.scss'

const FETCH_URL = '/api/login'
//const bcrypt = require('bcryptjs');
const crypto = require('crypto');
const saltRounds = 10;
const algorithm = 'aes-256-cbc';
const privatekey = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);

export default class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
        menuOpen: false,
        inputs: {
            username: '',
            password: '',
            check: 1
        }
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.submitLoginForm = this.submitLoginForm.bind(this)
    this.menuRef = React.createRef()
  }
  
  handleMenuClick (e) {
    e.stopPropagation()
    this.setState((state) => ({ menuOpen: !state.menuOpen }))
  }

  handleMenuClose (e) {
    e.stopPropagation()
    if (this.menuRef.current && !this.menuRef.current.contains(e.target)) {
      this.setState({ menuOpen: false })
    }
  }
  
    submitLoginForm (e) {
		e.preventDefault()
        e.stopPropagation()
		let elems = e.target.querySelectorAll('input, button');
		elems.forEach((v, i) => {
			v.disabled = true;
		});
        let formData = new FormData();
        Object.entries(this.state.inputs).map(([key, value]) => {
			if(key === 'password') {

				//const salt = bcrypt.genSaltSync(saltRounds);
				//const hash = bcrypt.hashSync(value, salt);
				const hash = this.encrypt(value);
				formData.append(key, hash.encryptedData);
				formData.append('salt', hash.iv); // for server side to decrypt
				formData.append('key', privatekey.toString('hex'));
			} else {
				formData.append(key, value);
			}
			return value;
        });
        fetch(FETCH_URL, {
            method: 'POST',
            mode:   'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData
		}).then(response => response.json())
		.then(result => {
			elems.forEach((v, i) => {
				v.disabled = false;
			});
			if(!result.data || !result.data.auth_token) {
				alert('Login Failed');
			} else {
				alert(`Login Succeeded, ${result.data.username}!`);
				document.cookie = `authtoken=${result.data.auth_token};domain=bfptt.cc${!!this.state.inputs.check ? ';max-age=' + 60*60*24*365*10 : ''};secure;samesite=strict`;
				if(!!result.data.redirecturl) window.location.href = result.data.redirecturl;
			}
		}).catch(error => {
			console.log(error);
			elems.forEach((v, i) => {
				v.disabled = false;
			});
			alert('Something happened');
		});
  }

    handleInputChange (e) {
		let oldStateCopy = Object.assign({}, this.state);
		oldStateCopy.inputs[e.target.name] = e.target.value;
        this.setState(oldStateCopy);
    }
    componentDidMount () {

    }
	
	
	encrypt(text) {
	 let cipher = crypto.createCipheriv(algorithm, Buffer.from(privatekey), iv);
	 let encrypted = cipher.update(text);
	 encrypted = Buffer.concat([encrypted, cipher.final()]);
	 return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
	}

	decrypt(text) {
	 let iv = Buffer.from(text.iv, 'hex');
	 let encryptedText = Buffer.from(text.encryptedData, 'hex');
	 let decipher = crypto.createDecipheriv(algorithm, Buffer.from(privatekey), iv);
	 let decrypted = decipher.update(encryptedText);
	 decrypted = Buffer.concat([decrypted, decipher.final()]);
	 return decrypted.toString();
	}


  render () {
    const { menuOpen } = this.state

    return (
      <Layout
        documentTitle={`LOGIN - BFPTT`}
        onClick={this.handleMenuClose}
      >
		<div className={`${styles['login-wrap']}`}>
			<div className={`${styles['login-html']}`}>
				<form name="loginForm" onSubmit={this.submitLoginForm}>
					<input id="tab-1" type="radio" name="tab" className={`${styles['sign-in']}`} checked/><label for="tab-1" className={`${styles.tab}`}>登入</label>
					<div className={`${styles['login-form']}`}>
						<div className={`${styles.group}`}>
							<label for="user" className={`${styles.label}`}>帳號</label>
							<input id="user" name="username" type="text" className={`${styles.input}`} onChange={this.handleInputChange}/>
						</div>
						<div className={`${styles.group}`}>
							<label for="pass" className={`${styles.label}`}>密碼</label>
							<input id="pass" name="password" type="password" className={`${styles.input}`} onChange={this.handleInputChange} dataType="password"/>
						</div>
						<div className={`${styles.group}`}>
							<input id="check" name="check" onChange={this.handleInputChange} type="checkbox" className={`${styles.check}`} defaultChecked/>
							<label for="check"><span className={`${styles.icon}`}></span>保持登入</label>
						</div>
						<div className={`${styles.group}`}>
							<input type="submit" className={`${styles.button}`} value="登入"/>
						</div>
						<div className={`${styles.hr}`}></div>
						<div className={`${styles['foot-lnk']}`}>
							<a href="forgot" className={`${styles['forgot-link']}`}>忘記密碼？</a>
						</div>
					</div>
				</form>
			</div>
		</div>
      </Layout>
    )
  }
}


