
import { hot } from 'react-hot-loader/root'
import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import Home from 'pages/Home'
import BoardList from 'pages/BoardList'
import Board from 'pages/Board'
import Article from 'pages/Article'
import Search from 'pages/Search'
import Terms from 'pages/Terms'
import Login from 'pages/Member'
import PageNotFound from 'pages/PageNotFound'

const Router = props => (
  <BrowserRouter>
    <LastLocationProvider>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/group' component={BoardList} />
        <Route exact path='/group/:board_group' component={BoardList} />
        <Route exact path='/board/:board' component={Board} />
        <Route exact path='/board/:board/:aid' component={Article} />
        <Route exact path='/search' component={Search} />
        <Route exact path='/terms' component={Terms} />
        <Route exact path='/login' component={Login} />
        <Route component={PageNotFound} />
      </Switch>
    </LastLocationProvider>
  </BrowserRouter>
)

export default hot(Router)
