
import React from 'react'
import { connect } from 'react-redux'
import { withLastLocation } from 'react-router-last-location'
import queryString from 'query-string'
import InfiniteScroll from 'components/InfiniteScroll'
import Layout from 'components/Layout'
import ArticleList from 'components/ArticleList'
import NotFound from 'components/NotFound'
import Spinner from 'components/Spinner'
import { getArticleList } from 'redux/Actions/ArticleList'
import { getBoardList, resetBoardList } from 'redux/Actions/BoardList'
import { searchBoardArticle } from 'redux/Actions/Search'
import { FETCHING_STATUS } from 'utils'

class Board extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isSearch: false
    }
    this.fetchDataOnMount = this.fetchDataOnMount.bind(this)
    this.fetchDataOnScroll = this.fetchDataOnScroll.bind(this)
    this.processQuery = this.processQuery.bind(this)
  }

  componentWillUnmount () {
    this.props.resetBoardList()
  }

  componentDidUpdate (prevProps, prevState) {
    const boardName = this.props.match.params.board
    const prevboardName = prevProps.match.params.board
    const search = this.props.location.search
    const prevSearch = prevProps.location.search

    if (search !== prevSearch) {
      const searchPayload = { ...this.processQuery(search) }

      if (JSON.stringify(searchPayload) !== JSON.stringify({})) {
        this.setState({ isSearch: true })
        this.props.searchBoardArticle({
          ...searchPayload,
          board: boardName,
          unix_time: 0
        })
      } else {
        this.setState({ isSearch: false })
        this.props.getArticleList({
          board: boardName,
          start_aid: ''
        })
        this.props.getBoardList({
          name: boardName
        })
      }
      // scroll the window to top
      window.scroll({ top: 0, left: 0 })
    } else if (boardName !== prevboardName) { // for change board
      this.props.getArticleList({
        board: boardName,
        start_aid: ''
      })
      this.props.getBoardList({
        name: boardName
      })
      // scroll the window to top
      window.scroll({ top: 0, left: 0 })
    }
  }

  fetchDataOnMount () {
    const { articleList, searchArticleList } = this.props
    const boardName = this.props.match.params.board
    const searchPayload = { ...this.processQuery(this.props.location.search) }
    const prevLocation = this.props.lastLocation
    let fromArticle = false

    // no fetching if return from article page
    if (prevLocation) {
      const regex = new RegExp(`/board/${boardName}/[A-Za-z0-9.]+`)
      fromArticle = regex.test(prevLocation.pathname)
    }

    if (JSON.stringify(searchPayload) !== JSON.stringify({})) {
      this.setState({ isSearch: true })
      if (fromArticle && searchArticleList.length) return

      this.props.searchBoardArticle({
        ...searchPayload,
        board: boardName,
        unix_time: 0
      })
    } else {
      this.setState({ isSearch: false })
      if (fromArticle && articleList.length) return

      this.props.getArticleList({
        board: boardName,
        start_aid: ''
      })
      this.props.getBoardList({
        name: boardName
      })
    }
  }

  fetchDataOnScroll () {
    const { articleList, searchArticleList } = this.props
    const boardName = this.props.match.params.board
    const searchPayload = { ...this.processQuery(this.props.location.search) }

    if (JSON.stringify(searchPayload) !== JSON.stringify({})) {
      if (!searchArticleList.length) return
      const unixTime = searchArticleList[searchArticleList.length - 1].unix_time

      this.props.searchBoardArticle({
        ...searchPayload,
        board: boardName,
        unix_time: unixTime
      })
    } else {
      if (!articleList.length) return
      const startAid = articleList[articleList.length - 1].aid

      this.props.getArticleList({
        board: boardName,
        start_aid: startAid
      })
    }
  }

  processQuery (query) {
    if (!query) return {}

    const boardName = this.props.match.params.board
    const searchPayload = queryString.parse(query)
    if (!searchPayload.search_type || !searchPayload.value) {
      this.props.history.push(`/board/${boardName}`)
    }

    if (isNaN(searchPayload.search_type)) {
      this.props.history.push(`/board/${boardName}`)
    }
    searchPayload.search_type = parseInt(searchPayload.search_type, 10)

    if (searchPayload.search_type === 2) {
      if (isNaN(searchPayload.value)) {
        this.props.history.push(`/board/${boardName}`)
      }
      searchPayload.value = parseInt(searchPayload.value, 10)
    }
    return searchPayload
  }

  render () {
    const {
      articleList,
      status,
      boardInfo,
      boardStatus,
      searchArticleList,
      searchStatus
    } = this.props
    const boardName = this.props.match.params.board
    const { isSearch } = this.state

    const searchPayload = { ...this.processQuery(this.props.location.search) }
    const searchInfo = [
      `標題搜尋: ${searchPayload.value}`,
      `作者搜尋: ${searchPayload.value}`,
      `推噓數 ${searchPayload.value >= 0 ? '>=' : '<='} ${searchPayload.value}`
    ][searchPayload.search_type]

    const notFound = isSearch
      ? ((searchStatus === FETCHING_STATUS.FAIL) ||
        (searchStatus === FETCHING_STATUS.DONE && searchArticleList.length === 0))
      : ((status === FETCHING_STATUS.FAIL) ||
        (boardStatus === FETCHING_STATUS.FAIL) ||
        (boardStatus === FETCHING_STATUS.DONE && boardInfo.status <= 0))

    const isFetching = isSearch
      ? (searchStatus === FETCHING_STATUS.FETCHING)
      : (status === FETCHING_STATUS.FETCHING) ||
        (boardStatus === FETCHING_STATUS.FETCHING)

    const articles = isSearch ? searchArticleList : articleList

    return (
      <InfiniteScroll
        fetchDataOnMount={() => this.fetchDataOnMount()}
        fetchDataOnScroll={() => this.fetchDataOnScroll()}
      >
        <Layout documentTitle={`${boardName} - BFPTT`}>
          <div className='container-fluid'>
            <div className='row justify-content-center'>
              <div
                className='col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 px-0'
                style={{ position: 'initial' }}
              >
                {
                  notFound
                    ? <NotFound />
                    : (
                      <Spinner isFetching={isFetching} init={!articles.length}>
                        <ArticleList
                          title={boardName}
                          articles={articles}
                          searchInfo={searchInfo}
                          isBoard
                        />
                      </Spinner>
                    )
                }
              </div>
            </div>
          </div>
        </Layout>
      </InfiniteScroll>
    )
  }
}

const mapStateToProps = (state) => ({
  articleList: state.articleList.index.data,
  status: state.articleList.index.status,
  boardInfo: state.boardList.index.data,
  boardStatus: state.boardList.index.status,
  searchArticleList: state.search.data,
  searchStatus: state.search.status
})
const mapDispatchToProps = (dispatch) => ({
  getArticleList: (payload) => dispatch(getArticleList(payload)),
  getBoardList: (payload) => dispatch(getBoardList(payload)),
  searchBoardArticle: (payload) => dispatch(searchBoardArticle(payload)),
  resetBoardList: () => dispatch(resetBoardList())
})

export default connect(mapStateToProps, mapDispatchToProps)(withLastLocation(Board))
