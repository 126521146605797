
import React from 'react'
import { Link } from 'react-router-dom'
import ListWrapper from 'components/ListWrapper'
import styles from './style.module.scss'

const Board = ({ board, link }) => (
  <Link className='text-decoration-none' to={link}>
    <div className={`${styles.preview} py-2`}>
      <div className='row'>
        <div className='col-12 col-lg-3'>{board.name}</div>
        <div className='col-3 col-lg-2 text-lg-right'>{board.board_class}</div>
        <div className='col-9 col-lg-7'>{board.title}</div>
      </div>
    </div>
  </Link>
)

const BoardList = ({ title, boards }) => (
  <ListWrapper title={title}>
    {
      boards.map((board, index) => {
        if (board.status <= 0) return null

        return (
          <Board
            key={index}
            board={board}
            link={(board.is_board ? '/board/' : '/group/') + board.name}
          />
        )
      })
    }
  </ListWrapper>
)

export default BoardList
