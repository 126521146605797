
import { createActions } from 'redux-actions'
import apiServer from './Server'
import { FETCHING_STATUS } from 'utils'

export const actions = createActions({
  ARTICLE_LIST: {
    INDEX: {
      STORE: null,
      APPEND: null,
      SET_STATUS: null
    },
    HOT: {
      STORE: null,
      SET_STATUS: null
    }
  }
})

export const getArticleList = (payload) => dispatch => {
  // clear previous data
  if (payload.start_aid === '') {
    dispatch(actions.articleList.index.store([]))
  }

  dispatch(actions.articleList.index.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .post('/article/getArticleList', payload)
    .then(({ data: response }) => {
      if (payload.start_aid === '') {
        dispatch(actions.articleList.index.store(response.data))
      } else {
        dispatch(actions.articleList.index.append(response.data))
      }
      dispatch(actions.articleList.index.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => dispatch(actions.articleList.index.setStatus(FETCHING_STATUS.FAIL)))
}

export const getHotArticleList = (payload) => dispatch => {
  dispatch(actions.articleList.hot.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .get('/article/getHotArticleList', payload)
    .then(({ data: response }) => {
      dispatch(actions.articleList.hot.store(response.data))
      dispatch(actions.articleList.hot.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => dispatch(actions.articleList.hot.setStatus(FETCHING_STATUS.FAIL)))
}
