
import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './style.module.scss'

class _SearchBarBoard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search_type: 0,
      value: ''
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleSearch () {
    if (this.state.value === '') return

    const { pathname } = this.props.location.pathname
    const type = this.state.search_type
    const { value } = this.state

    if (type === 2) {
      if (isNaN(value)) {
        window.alert('請輸入數字')
        return
      }
    }

    this.props.history.push({
      pathname: pathname,
      search: `?search_type=${type}&value=${value}`
    })
  }

  handleKeyPress (event) {
    if (event.key === 'Enter') {
      this.handleSearch()
    }
  }

  render () {
    const type = this.state.search_type
    const colors = [
      type === 0 ? 'text-black' : 'text-light-grey',
      type === 1 ? 'text-black' : 'text-light-grey',
      type === 2 ? 'text-black' : 'text-light-grey'
    ]
    const placeholder = [
      '請輸入文章標題',
      '請輸入作者ID',
      '請輸入推噓數'
    ][type]

    return (
      <div className='input-group'>
        <div className='input-group-prepend'>
          <span
            className={`input-group-text bg-white p-2 clickable ${colors[0]}`}
            onClick={() => this.setState({ search_type: 0 })}
          >
            <i className='fas fa-pen-square fa-lg' />
          </span>
          <span
            className={`input-group-text bg-white p-2 clickable ${colors[1]}`}
            onClick={() => this.setState({ search_type: 1 })}
          >
            <i className='far fa-user' />
          </span>
          <span
            className={`input-group-text bg-white p-2 clickable ${colors[2]}`}
            onClick={() => this.setState({ search_type: 2 })}
          >
            <i className='far fa-thumbs-up' />
          </span>
        </div>
        <input
          className={`form-control ${styles.inputBoard}`}
          placeholder={placeholder}
          value={this.state.value}
          onChange={(e) => this.setState({ value: e.target.value })}
          onKeyPress={this.handleKeyPress}
        />
        <div className='input-group-append'>
          <span
            className='input-group-text bg-white p-2 clickable'
            onClick={this.handleSearch}
          >
            <i className='fas fa-search' />
          </span>
        </div>
      </div>
    )
  }
}

class _SearchBarFullSite extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleSearch () {
    const { value } = this.state
    if (this.state.value === '') return

    this.props.history.push({
      pathname: '/search',
      search: `?value=${value}`,
      state: { enable: true }
    })
  }

  handleKeyPress (event) {
    if (event.key === 'Enter') {
      this.handleSearch()
    }
  }

  render () {
    return (
      <div className='input-group'>
        <input
          className={`form-control ${styles.inputLong}`}
          placeholder='全站搜尋文章標題、作者ID'
          value={this.state.value}
          onChange={(e) => this.setState({ value: e.target.value })}
          onKeyPress={this.handleKeyPress}
        />
        <div className='input-group-append'>
          <span
            className='input-group-text bg-white p-2 clickable'
            onClick={this.handleSearch}
          >
            <i className='fas fa-search' />
          </span>
        </div>
      </div>
    )
  }
}

class _SearchBarHome extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleSearch () {
    const { value } = this.state
    if (this.state.value === '') return

    this.props.history.push({
      pathname: '/search',
      search: `?value=${value}`,
      state: { enable: true }
    })
  }

  handleKeyPress (event) {
    if (event.key === 'Enter') {
      this.handleSearch()
    }
  }

  render () {
    const button = (
      <button className={`btn ${styles.btn} px-3 `}>
        <i className='fas fa-search fa-sm mr-2' />
        搜尋
      </button>
    )

    return (
      <div className='row justify-content-center'>
        <div className='col-12 d-flex align-items-center'>
          <input
            className={`${styles.inputHome} px-3 px-sm-4`}
            placeholder='全站搜尋文章標題、作者ID'
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
            onKeyPress={this.handleKeyPress}
          />
          {/* button in input */}
          <div className={`d-none d-sm-block ${styles.btnWrapper}`} onClick={this.handleSearch}>
            {button}
          </div>
        </div>
        {/* button below input */}
        <div className='d-sm-none col-12 text-center pt-4' onClick={this.handleSearch}>
          {button}
        </div>
      </div>
    )
  }
}

const SearchBarBoard = withRouter(_SearchBarBoard)
const SearchBarFullSite = withRouter(_SearchBarFullSite)
const SearchBarHome = withRouter(_SearchBarHome)

export { SearchBarBoard, SearchBarFullSite, SearchBarHome }
