
import { createActions } from 'redux-actions'
import apiServer from './Server'
import { FETCHING_STATUS } from 'utils'

export const actions = createActions({
  SEARCH: {
    STORE: null,
    APPEND: null,
    SET_STATUS: null
  }
})

export const searchAllArticle = (payload) => dispatch => {
  // clear previous searching data
  if (payload.unix_time === 0) {
    dispatch(actions.search.store([]))
  }

  dispatch(actions.search.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .post('/search/getAllArticle', payload)
    .then(({ data: response }) => {
      if (payload.unix_time === 0) {
        dispatch(actions.search.store(response.data))
      } else {
        dispatch(actions.search.append(response.data))
      }
      dispatch(actions.search.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => actions.search.setStatus(FETCHING_STATUS.FAIL))
}

export const searchBoardArticle = (payload) => dispatch => {
  // clear previous searching data
  if (payload.unix_time === 0) {
    dispatch(actions.search.store([]))
  }

  dispatch(actions.search.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .post('/search/getBoardArticle', payload)
    .then(({ data: response }) => {
      if (payload.unix_time === 0) {
        dispatch(actions.search.store(response.data))
      } else {
        dispatch(actions.search.append(response.data))
      }
      dispatch(actions.search.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => actions.search.setStatus(FETCHING_STATUS.FAIL))
}
