
import { handleActions } from 'redux-actions'
import { FETCHING_STATUS } from 'utils'

const initialState = {
  index: {
    data: [],
    status: FETCHING_STATUS.IDLE
  },
  hot: {
    data: [],
    status: FETCHING_STATUS.IDLE
  }
}

export default handleActions({
  ARTICLE_LIST: {
    INDEX: {
      STORE: (state, action) => ({
        ...state,
        index: {
          ...state.index,
          data: action.payload
        }
      }),
      APPEND: (state, action) => ({
        ...state,
        index: {
          ...state.index,
          data: [...state.index.data, ...action.payload]
        }
      }),
      SET_STATUS: (state, action) => ({
        ...state,
        index: {
          ...state.index,
          status: action.payload
        }
      })
    },
    HOT: {
      STORE: (state, action) => ({
        ...state,
        hot: {
          ...state.hot,
          data: action.payload
        }
      }),
      SET_STATUS: (state, action) => ({
        ...state,
        hot: {
          ...state.hot,
          status: action.payload
        }
      })
    }
  }
}, initialState)
