
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link, NavLink } from 'react-router-dom'
import Logo from 'components/Logo'
import { SearchBarFullSite } from 'components/Search'
import { getHotBoardList } from 'redux/Actions/BoardList'
import { lockBodyScroll, unlockBodyScroll } from 'utils'
import styles from './style.module.scss'

const SidebarMenu = ({ hotBoard, show, onMenuClose }) => (
  <div className={`${styles.sidebarMask} ${show && styles.show}`} onClick={onMenuClose}>
    <div className={`${styles.menuList} ${show && styles.show} p-3`}>
      <div className='clickable px-2 pb-3' onClick={onMenuClose}>
        <i className='fas fa-chevron-left fa-lg' />
      </div>
      <div>
        <NavLink
          className={`${styles.menuItem} p-2`}
          activeClassName={styles.active}
          to='/group'
        >
          看板列表
        </NavLink>
        {
          hotBoard.map((board, index) => {
            if (board.status <= 0) return null
            return (
              <NavLink
                className={`${styles.menuItem} p-2`}
                activeClassName={styles.active}
                to={`/board/${board.name}`}
                key={index}
              >
                {board.name}
              </NavLink>
            )
          })
        }
      </div>
    </div>
  </div>
)

class Navbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false,
      searchOpen: false
    }
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleSearchOpen = this.handleSearchOpen.bind(this)
    this.handleSearchClose = this.handleSearchClose.bind(this)
    this.handleBackButton = this.handleBackButton.bind(this)
  }

  componentDidMount () {
    this.props.getHotBoardList()
  }

  handleMenuOpen () {
    lockBodyScroll()
    this.setState({ menuOpen: true })
  }

  handleMenuClose () {
    unlockBodyScroll()
    this.setState({ menuOpen: false })
  }

  handleSearchOpen () {
    this.setState({ searchOpen: true })
  }

  handleSearchClose () {
    this.setState({ searchOpen: false })
  }

  handleBackButton () {
    const { key } = this.props.location
    const { board } = this.props.match.params

    // there is no key when first visiting the website in tab
    if (key) this.props.history.goBack()
    else this.props.history.push(`/board/${board}`)
  }

  render () {
    const { menuOpen, searchOpen } = this.state
    const { hotBoard } = this.props
    const { pathname } = this.props.location
    const { board, aid } = this.props.match.params
    const bgColor = 'bg-' + (pathname === '/' ? 'dark' : 'grey')

    return (
      <>
        <nav className={`${styles.navbar} ${bgColor} py-2`}>
          <div className={`${styles.navList}`}>
            <div className={`${styles.leftList}`}>
              {
                // only in article page display back button
                board && aid && (
                  <span className={styles.back} onClick={this.handleBackButton}>
                    <i className='fas fa-arrow-left fa-lg' />
                  </span>
                )
              }
              <span className='clickable' onClick={this.handleMenuOpen}>
                <i className='fas fa-bars fa-lg' />
              </span>
            </div>
            {
              // only non-homepage display logo & search in navbar
              pathname !== '/' && (
                <>
                  <div>
                    <div className='d-none d-lg-block'>
                      <Link className='text-decoration-none' to='/'>
                        <Logo navbarLogo='navbarLogo' />
                      </Link>
                    </div>
                    <div className='d-lg-none'>
                      {
                        !searchOpen && (
                          <Link className='text-decoration-none' to='/'>
                            <Logo navbarLogo='navbarLogo' />
                          </Link>
                        )
                      }
                    </div>
                  </div>
                  <div className={`${styles.rightList}`}>
                    <div className='d-none d-lg-block'>
                      <SearchBarFullSite />
                    </div>
                    <div className='d-lg-none'>
                      <div className='d-flex align-items-center'>
                        {
                          searchOpen
                            ? (
                              <>
                                <i
                                  className='fas fa-chevron-right fa-lg mr-2 mr-sm-3 clickable'
                                  onClick={this.handleSearchClose}
                                />
                                <SearchBarFullSite />
                              </>
                            )
                            : (
                              <i
                                className='fas fa-search fa-lg clickable'
                                onClick={this.handleSearchOpen}
                              />
                            )
                        }
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </nav>

        <SidebarMenu
          hotBoard={hotBoard}
          show={menuOpen}
          onMenuClose={this.handleMenuClose}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  hotBoard: state.boardList.hot.data
})
const mapDispatchToProps = (dispatch) => ({
  getHotBoardList: () => dispatch(getHotBoardList())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar))
