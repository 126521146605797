
import React from 'react'
import styles from './style.module.scss'

const NotFound = () => (
  <div className={`container ${styles.wrapper}`}>
    <div className={styles.text1}>崩╰(〒皿〒)╯潰</div>
    <div className={styles.text2}>找不到您要搜尋的內容</div>
  </div>
)

export default NotFound
