
import { combineReducers } from 'redux'
import article from './Article'
import articleList from './ArticleList'
import boardList from './BoardList'
import search from './Search'

export default combineReducers({
  article,
  articleList,
  boardList,
  search
})
