
import React from 'react'
import { connect } from 'react-redux'
import { withLastLocation } from 'react-router-last-location'
import queryString from 'query-string'
import InfiniteScroll from 'components/InfiniteScroll'
import Layout from 'components/Layout'
import NotFound from 'components/NotFound'
import Spinner from 'components/Spinner'
import ArticleList from 'components/ArticleList'
import { searchAllArticle } from 'redux/Actions/Search'
import { FETCHING_STATUS } from 'utils'

class Search extends React.Component {
  componentDidUpdate (prevProps) {
    const search = this.props.location.search
    const prevSearch = prevProps.location.search

    // for change search payload
    if (search !== prevSearch) {
      // clear state, or click article and back will fetch again
      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: this.props.location.search,
        state: {}
      })

      const value = this.processQuery(this.props.location.search)
      this.props.searchAllArticle({
        value: value,
        unix_time: 0
      })
      // scroll the window to top
      window.scroll({ top: 0, left: 0 })
    }
  }

  fetchDataOnMount () {
    const { articleList } = this.props
    const enable = this.props.location.state && this.props.location.state.enable
    const value = this.processQuery(this.props.location.search)
    const prevLocation = this.props.lastLocation

    // clear state, or click article and back will fetch again
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: this.props.location.search,
      state: {}
    })

    // no fetching if return from article page
    if (!enable && prevLocation) {
      const regex = new RegExp('/board/[A-Za-z0-9_-]+/[A-Za-z0-9.]+')
      if (regex.test(prevLocation.pathname) && articleList.length) return
    }

    this.props.searchAllArticle({
      value: value,
      unix_time: 0
    })
  }

  fetchDataOnScroll () {
    const { articleList } = this.props
    const value = this.processQuery(this.props.location.search)

    if (!articleList.length) return
    const unixTime = articleList[articleList.length - 1].unix_time

    this.props.searchAllArticle({
      value: value,
      unix_time: unixTime
    })
  }

  processQuery (query) {
    if (!query) {
      this.props.history.push('/')
    }

    const searchPayload = queryString.parse(query)
    if (!searchPayload.value) {
      this.props.history.push('/')
    }

    return searchPayload.value
  }

  render () {
    const { articleList, status } = this.props
    const searchValue = this.processQuery(this.props.location.search)
    const isFetching = (status === FETCHING_STATUS.FETCHING)
    const notFound = ((status === FETCHING_STATUS.FAIL) ||
                      (status === FETCHING_STATUS.DONE && articleList.length === 0))

    return (
      <InfiniteScroll
        fetchDataOnMount={() => this.fetchDataOnMount()}
        fetchDataOnScroll={() => this.fetchDataOnScroll()}
      >
        <Layout documentTitle='全站搜尋 - BFPTT'>
          <div className='container-fluid'>
            <div className='row justify-content-center'>
              <div
                className='col-12 col-sm-10 col-md-9 col-lg-8 px-0'
                style={{ position: 'initial' }}
              >
                {
                  notFound
                    ? <NotFound />
                    : (
                      <Spinner isFetching={isFetching} init={!articleList.length}>
                        <ArticleList title={`全站搜尋: ${searchValue}`} articles={articleList} />
                      </Spinner>
                    )
                }
              </div>
            </div>
          </div>
        </Layout>
      </InfiniteScroll>
    )
  }
}

const mapStateToProps = (state) => ({
  articleList: state.search.data,
  status: state.search.status
})
const mapDispatchToProps = (dispatch) => ({
  searchAllArticle: (payload) => dispatch(searchAllArticle(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(withLastLocation(Search))
