import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import EditRecord from 'components/Article/EditRecord'
import { Statisticbar, Comment, AuthorResponse } from 'components/Article/Response'
import NotFound from 'components/NotFound'
import Spinner from 'components/Spinner'
import { getArticle, resetArticle } from 'redux/Actions/Article'
import { htmlParse } from 'utils'
import { FacebookProvider, Comments } from 'react-facebook'
//import FacebookProvider from '../../utils/FacebookProvider'
import styles from './style.module.scss'

class Index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false,
      editOpen: false
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleEditOpen = this.handleEditOpen.bind(this)
    this.handleEditClose = this.handleEditClose.bind(this)

    this.menuRef = React.createRef()
  }

  componentDidMount () {
    const { board, aid } = this.props.match.params
    this.props.getArticle({
      board: board,
      aid: aid,
      is_db: true
    })
  }

  componentWillUnmount () {
    this.props.resetArticle()
  }

  handleMenuClick (e) {
    e.stopPropagation()
    this.setState((state) => ({ menuOpen: !state.menuOpen }))
  }

  handleMenuClose (e) {
    e.stopPropagation()
    if (this.menuRef.current && !this.menuRef.current.contains(e.target)) {
      this.setState({ menuOpen: false })
    }
  }

  handleEditOpen (e) {
    e.stopPropagation()
    this.setState({ menuOpen: false, editOpen: true })
  }

  handleEditClose (e) {
    e.stopPropagation()
    this.setState({ editOpen: false })
  }

  render () {
    const { menuOpen, editOpen } = this.state
    const { board, aid } = this.props.match.params
    const { article } = this.props
    const { headline, ip, content, response } = article
    const purposeNum = article.purpose_num
    const originalLink = `https://www.ptt.cc/bbs/${board}/${aid}.html`
	const replacedLink = originalLink.replace(/https?:\/\/www\.ptt\.cc\/bbs\/(.+?)\.html$/, 'https://www.bfptt.cc/board/$1');

    const notFound = article.is_error || (article.status <= 0)
    const isFetching = (Object.keys(article.headline).length === 0 && article.content === '')

    return (
      <Layout
        documentTitle={headline.title && `${headline.title} - ${board} - BFPTT`}
        onClick={this.handleMenuClose}
      >
        {
          notFound
            ? <NotFound />
            : isFetching
              ? <Spinner isFetching={isFetching} init />
              : (
                <div className='container py-2 py-sm-4'>
                  <div className={`${styles.header} my-2`}>
                    <div className='flex-grow-1'>{headline.title}</div>
                    <div className='dropdown' ref={this.menuRef}>
                      <button
                        className={`btn ${styles.btnCircle}`}
                        onClick={this.handleMenuClick}
                      >
                        <i className='fas fa-ellipsis-v' />
                      </button>
                      <div className={`dropdown-menu dropdown-menu-right text-center
                                      ${styles.menu} ${menuOpen && 'show'}`}
                      >
                        <a
                          className='dropdown-item'
                          href={originalLink}
                          target='_blank'
                          rel='noopener noreferrer nofollow'
                        >
                          原文連結
                        </a>
                        <div
                          className={`dropdown-item clickable
                                    ${!article.edit.length && 'disabled'}`}
                          onClick={this.handleEditOpen}
                        >
                          編輯紀錄
                        </div>
                      </div>
                    </div>
                    {
                      editOpen && (
                        <EditRecord
                          records={article.edit}
                          onClose={this.handleEditClose}
                        />
                      )
                    }
                  </div>
                  <div className={styles.info}>
                    <div>作者: {`${headline.author} (${headline.nickname})`}</div>
                    <div>時間: {headline.date_time}</div>
                  </div>
                  <div className={`${styles.content} mt-3 mt-sm-4 mb-4`} onCopy={(e) => {
						const selection = document.getSelection();
						e.clipboardData.setData('text/plain', selection.toString()
							.replace(/([^\r\n]+)([\r\n]{2})([^\r\n]+)/g, '$1$3')
							.replace(/([^\r\n]+)([\r\n]{1,2})([^\r\n]+)/g, '$1$3')
						);
						e.preventDefault();
				  }}>
                    {
                      htmlParse(content)
                    }
                    {
                      ip && <span className='f2'>※ 來自: {ip}</span>
                    }
                    <br />
                    <span className='f2'>
                      ※ 原文連結:
                      <a
                        className='ml-2'
                        href={originalLink}
                        target='_blank'
                        rel='noopener noreferrer nofollow'
                      >
                        {originalLink}
                      </a>
                    </span>
					
					<div className={`${styles._1539kb4KMXLFwHawrQ1CkY}`}>
						<span className={`${styles._3ac81VC3FuUHWAbt8oS2pD}`}></span>
						<h2 className={`${styles._2EXjEs9OBwJ0QSV2zQL5ce}`}>FB留言</h2>
					</div>
					
					<FacebookProvider appId="643647906417490" language="zh_TW" xfbml={true} version="v7.0" wait>
						<Comments href={replacedLink} colorScheme="dark" />
					</FacebookProvider>

                    <Statisticbar
                      push={purposeNum.push}
                      boo={purposeNum.boo}
                      arrow={purposeNum.arrow}
                    />
                    {
                      response.map((res, index) => {
                        if (!res.is_comment) {
                          return <AuthorResponse key={index} content={htmlParse(res.content)} />
                        } else if (res.status > 0) {
                          return <Comment key={index} comment={res} />
                        }
                        return null
                      })
                    }
                  </div>
                </div>
              )
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  article: state.article.data
})
const mapDispatchToProps = (dispatch) => ({
  getArticle: (payload) => dispatch(getArticle(payload)),
  resetArticle: () => dispatch(resetArticle())
})

export default connect(mapStateToProps, mapDispatchToProps)(Index)
