
import React from 'react'
import { htmlParse, lockBodyScroll, unlockBodyScroll } from 'utils'
import styles from './style.module.scss'

class RecordItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState((state) => ({
      open: !state.open
    }))
  }

  render () {
    const { open } = this.state
    const { record } = this.props

    return (
      <div className={`${styles.recordItem} mx-4 mx-sm-5 py-2`}>
        <div className={styles.headerLine} onClick={this.handleClick}>
          <div className={styles.dateTime}>{record.date_time}</div>
          {
            open
              ? <i className='fas fa-chevron-up' />
              : <i className='fas fa-chevron-down' />
          }
        </div>
        {
          open && (
            <div className={`${styles.content} py-2`}>{htmlParse(record.content)}</div>
          )
        }
      </div>
    )
  }
}

class EditRecord extends React.Component {
  componentDidMount () {
    lockBodyScroll()
  }

  componentWillUnmount () {
    unlockBodyScroll()
  }

  render () {
    return (
      <div className={styles.backgroundMask} onClick={this.props.onClose}>
        <div className={`${styles.wrapper}`} onClick={e => e.stopPropagation()}>
          <div className={`${styles.header} mx-3 py-2`}>
            <div className='flex-grow-1'>編輯紀錄</div>
            <i className='fas fa-times clickable' onClick={this.props.onClose} />
          </div>
          {
            this.props.records.map((record, index) => (
              <RecordItem key={index} record={record} />
            ))
          }
        </div>
      </div>
    )
  }
}

export default EditRecord
