
import React from 'react'
import Layout from 'components/Layout'
import NotFound from 'components/NotFound'

const Terms = () => (
  <Layout documentTitle='BFPTT 服務條款'>
    <NotFound />
  </Layout>
)

export default Terms
