
import React from 'react'
import styles from './style.module.scss'

const Logo = ({ homePage, navbarLogo }) => (
  <div className={`${styles.logo} ${homePage && styles.homePage} ${navbarLogo && styles.navbarLogo}`}>
    <img src='/LOGO.png' alt='BFPTT' />
  </div>
)

export default Logo