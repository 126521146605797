
import { handleActions } from 'redux-actions'
import { FETCHING_STATUS } from 'utils'

const initialState = {
  index: {
    data: {
      status: 1,
      is_board: false,
      is_r18: false,
      children: []
    },
    status: FETCHING_STATUS.IDLE
  },
  hot: {
    data: [],
    status: FETCHING_STATUS.IDLE
  }
}

export default handleActions({
  BOARD_LIST: {
    INDEX: {
      STORE: (state, action) => ({
        ...state,
        index: {
          ...state.index,
          data: action.payload
        }
      }),
      RESET: (state, action) => ({
        ...state,
        index: {
          ...state.index,
          data: initialState.index.data
        }
      }),
      SET_STATUS: (state, action) => ({
        ...state,
        index: {
          ...state.index,
          status: action.payload
        }
      })
    },
    HOT: {
      STORE: (state, action) => ({
        ...state,
        hot: {
          ...state.hot,
          data: action.payload
        }
      }),
      SET_STATUS: (state, action) => ({
        ...state,
        hot: {
          ...state.hot,
          status: action.payload
        }
      })
    }
  }
}, initialState)
