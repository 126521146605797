
import React from 'react'
import Layout from 'components/Layout'
import NotFound from 'components/NotFound'

const PageNotFound = () => (
  <Layout documentTitle='錯誤 - BFPTT'>
    <NotFound />
  </Layout>
)

export default PageNotFound
