
import React from 'react'
import styles from './style.module.scss'

const Spinner = ({ children, isFetching, init }) => (
  <>
    {children}
    {
      isFetching && (
        <span className={init ? styles.spinnerCenter : styles.spinner}>
          <i className='fa fa-circle-o-notch fa-spin' />
        </span>
      )
    }
  </>
)

export default Spinner
