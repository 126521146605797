
import { createActions } from 'redux-actions'
import apiServer from './Server'
import { FETCHING_STATUS } from 'utils'

export const actions = createActions({
  ARTICLE: {
    STORE: null,
    RESET: null,
    SET_STATUS: null
  }
})

export const getArticle = (payload) => dispatch => {
  dispatch(actions.article.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .post('/article/getArticle', payload)
    .then(({ data: response }) => {
      dispatch(actions.article.store(response.data))
      dispatch(actions.article.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => dispatch(actions.article.setStatus(FETCHING_STATUS.FAIL)))
    .then(() => {
      // after get data from db, get data from crawler
      if (payload.is_db) {
        dispatch(getArticle({ ...payload, is_db: false }))
      }
    })
}

export const resetArticle = (payload) => dispatch => {
  dispatch(actions.article.reset())
}
