
import React from 'react'
import { connect } from 'react-redux'
import Layout from 'components/Layout'
import Logo from 'components/Logo'
import { SearchBarHome } from 'components/Search'
import ArticleList from 'components/ArticleList'
import { getHotArticleList } from 'redux/Actions/ArticleList'

class Home extends React.Component {
  componentDidMount () {
    this.props.getHotArticleList()
  }

  render () {
    return (
      <Layout documentTitle='首頁 - BFPTT'>
        <div className='container-fluid'>
          <div className='row justify-content-center bg-grey'>
            <div className='col-12'>
              <div className='row justify-content-center'>
                <div>
                  <Logo homePage />
                </div>
              </div>
            </div>
            <div className='col-10 col-sm-8 col-md-7 col-lg-5 mb-4 mb-sm-5'>
              <SearchBarHome />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 px-0'>
              <ArticleList title='所有熱門文章' articles={this.props.articleList} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  articleList: state.articleList.hot.data
})
const mapDispatchToProps = (dispatch) => ({
  getHotArticleList: () => dispatch(getHotArticleList())
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
