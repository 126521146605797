
import { handleActions } from 'redux-actions'
import { FETCHING_STATUS } from 'utils'

const initialState = {
  data: [],
  status: FETCHING_STATUS.IDLE
}

export default handleActions({
  SEARCH: {
    STORE: (state, action) => ({ ...state, data: action.payload }),
    APPEND: (state, action) => ({ ...state, data: [...state.data, ...action.payload] }),
    SET_STATUS: (state, action) => ({ ...state, status: action.payload })
  }
}, initialState)
