
import React from 'react'
import Navbar from 'components/Navbar'
import styles from './style.module.scss'

class Layout extends React.Component {
  componentDidMount () {
    const { documentTitle } = this.props
    if (documentTitle) document.title = documentTitle
    window.scroll({ top: 0, left: 0 })
  }

  componentDidUpdate (prevProps) {
    const { documentTitle } = this.props
    if (documentTitle && documentTitle !== prevProps.documentTitle) {
      document.title = documentTitle
    }
  }

  render () {
    const { children, onClick } = this.props

    return (
      <div>
        <Navbar />
        <div className={styles.pageWrapper} onClick={onClick}>
          {children}
        </div>
      </div>
    )
  }
}

export default Layout
