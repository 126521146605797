
import { createActions } from 'redux-actions'
import apiServer from './Server'
import { FETCHING_STATUS } from 'utils'

export const actions = createActions({
  BOARD_LIST: {
    INDEX: {
      STORE: null,
      RESET: null,
      SET_STATUS: null
    },
    HOT: {
      STORE: null,
      SET_STATUS: null
    }
  }
})

export const getBoardList = (payload) => dispatch => {
  dispatch(actions.boardList.index.reset())
  dispatch(actions.boardList.index.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .post('/board/getBoard', payload)
    .then(({ data: response }) => {
      dispatch(actions.boardList.index.store(response.data))
      dispatch(actions.boardList.index.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => dispatch(actions.boardList.index.setStatus(FETCHING_STATUS.FAIL)))
}

export const resetBoardList = () => dispatch => {
  dispatch(actions.boardList.index.reset())
}

export const getHotBoardList = () => dispatch => {
  dispatch(actions.boardList.hot.setStatus(FETCHING_STATUS.FETCHING))
  apiServer
    .get('/board/getHotBoard')
    .then(({ data: response }) => {
      dispatch(actions.boardList.hot.store(response.data))
      dispatch(actions.boardList.hot.setStatus(FETCHING_STATUS.DONE))
    })
    .catch(() => dispatch(actions.boardList.hot.setStatus(FETCHING_STATUS.FAIL)))
}
