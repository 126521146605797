
import { handleActions } from 'redux-actions'
import { FETCHING_STATUS } from 'utils'

const initialState = {
  data: {
    headline: {},
    ip: '',
    content: '',
    is_error: false,
    purpose_num: {},
    edit: [],
    response: []
  },
  status: FETCHING_STATUS.IDLE
}

export default handleActions({
  ARTICLE: {
    STORE: (state, action) => ({ ...state, data: action.payload }),
    RESET: (state, action) => ({ ...state, data: initialState.data }),
    SET_STATUS: (state, action) => ({ ...state, status: action.payload })
  }
}, initialState)
