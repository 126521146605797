
import React from 'react'
import { Link } from 'react-router-dom'
import ListWrapper from 'components/ListWrapper'
import { SearchBarBoard } from 'components/Search'
import styles from './style.module.scss'

const colorStyle = (num) => {
  if (num < 0) return styles.grey
  if (num === 0) return styles.white
  if (num < 10) return styles.green
  if (num < 100) return styles.yellow
  return styles.red
}

const PreviewHot = ({ article, link }) => (
  <Link className='text-decoration-none' to={link}>
    <div className={`${styles.preview} py-2 py-md-3`}>
      <div className={styles.badge}>{article.board}</div>
      <div className={`${styles.title} mt-2 mb-1`}>{article.headline.title}</div>
      <div className={styles.content}>{article.content}</div>
      <div className={`${styles.bottom} mt-1`}>
        <div className={`${styles.push} ${colorStyle(article.headline.pb_num)}`}>
          {Math.abs(article.headline.pb_num)}
        </div>
        <div>{article.headline.author}</div>
      </div>
    </div>
  </Link>
)

const Preview = ({ article, link, fullSite }) => (
  <Link className='text-decoration-none' to={link}>
    <div className={`${styles.preview} py-1 py-sm-2`}>
      <div className={`${styles.top} mt-1`}>
        <div className={`${styles.push} ${colorStyle(article.headline.pb_num)}`}>
          {Math.abs(article.headline.pb_num)}
        </div>
        <div className='mx-3'>{article.headline.author}</div>
        {
          article.is_pin && <div className={styles.pin}>置頂</div>
        }
        {
          fullSite && <div className={styles.pin}>{article.board}</div>
        }
      </div>
      <div className={`${styles.title} my-1`}>{article.headline.title}</div>
      <div className={`${styles.bottom} mt-1`}>
        <div className={`${styles.push}`} />
        <div>{article.headline.date_time}</div>
      </div>
    </div>
  </Link>
)

const ArticleList = ({ title, articles, searchInfo, isBoard }) => (
  <ListWrapper title={title} titleSupplement={searchInfo} link={isBoard && `/board/${title}`}>
    {
      isBoard && (
        <div className='d-flex mb-3'>
          <SearchBarBoard />
        </div>
      )
    }
    {
      articles.map((article, index) => {
        if (article.status <= 0) return null

        const link = article.board
          ? `/board/${article.board}/${article.aid}`
          : `/board/${title}/${article.aid}`

        return (title === '所有熱門文章'
          ? <PreviewHot key={index} article={article} link={link} />
          : <Preview key={index} article={article} link={link} fullSite={article.board} />
        )
      })
    }
  </ListWrapper>
)

export default ArticleList
