
import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import Reducer from 'redux/Reducers'
import Router from 'Router'

const store = createStore(Reducer, applyMiddleware(thunk))

// set HMR for Redux
if (module.hot) {
  module.hot.accept('redux/Reducers', () => store.replaceReducer(Reducer))
}

function App () {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  )
}

export default App
