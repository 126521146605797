
import parse from 'html-react-parser'
function htmlParse (content) {
  return parse(content, {
    replace: (domNode) => {
      // for imgur embed script
      if (domNode.type === 'script') {
        const script = document.createElement('script')
        script.src = '//s.imgur.com/min/embed.js'
        script.charset = 'utf-8'
        script.async = true
        document.body.appendChild(script)
		return ''
      } else {
        return domNode
      }
    }
  })
}

function lockBodyScroll () {
  document.documentElement.style.overflowY = 'hidden'
  document.body.style.overflowY = 'hidden'
}

function unlockBodyScroll () {
  document.documentElement.removeAttribute('style')
  document.body.removeAttribute('style')
}

const FETCHING_STATUS = {
  IDLE: 0,
  FETCHING: 1,
  DONE: 2,
  FAIL: 3
}

export {
  htmlParse,
  lockBodyScroll,
  unlockBodyScroll,
  FETCHING_STATUS
}
