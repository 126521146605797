
import React from 'react'
import styles from './style.module.scss'

const Statisticbar = ({ push, boo, arrow }) => (
  <div className={`${styles.statistic} mt-2 py-1 py-sm-2 mb-3`}>
    <div>
      <i className={`far fa-thumbs-up mr-1 ${styles.push}`} />
      {push}
    </div>
    <div>
      <i className={`far fa-thumbs-down mr-1 ${styles.boo}`} />
      {boo}
    </div>
    <div>
      <i className={`far fa-hand-point-right mr-1 ${styles.arrow}`} />
      {arrow}
    </div>
  </div>
)

const Comment = ({ comment }) => {
  const icon = {
    0: <i className={`far fa-thumbs-up ${styles.push}`} />,
    1: <i className={`far fa-thumbs-down ${styles.boo}`} />,
    2: <i className={`far fa-hand-point-right ${styles.arrow}`} />
  }[comment.purpose]

  const text = comment.text.split(/\bhttps?:\/\/[^\s\u4e00-\u9fa5]+/gi)
  const url = comment.text.match(/\bhttps?:\/\/[^\s\u4e00-\u9fa5]+/gi)
  let texts = []
  if (url) {
    for (let i = 0; i < url.length; i++) {
      texts.push({ is_url: false, text: text[i] })
      texts.push({ is_url: true, text: url[i] })
    }
    texts.push({ is_url: false, text: text[text.length - 1] })
  } else {
    texts = text.map((t, i) => ({ is_url: false, text: t }))
  }

  return (
    <div className={styles.commentWrapper}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.comment}>
        <div className='d-flex align-items-center'>
          <div className={`${styles.author} mr-2`}>{comment.author}</div>
          <div className={`${styles.rank} mr-1`}>{comment.rank}</div>
          {icon}
          <div className={`${styles.floor} ml-1`}>{`/${comment.floor}F`}</div>
          <div className={styles.ipDateTime}>
            {
              comment.ip && <span className='d-none d-sm-inline'>{comment.ip}</span>
            }
            {
              comment.date && <span className='ml-2'>{comment.date}</span>
            }
            {
              comment.time && <span className='ml-2'>{comment.time}</span>
            }
          </div>
        </div>
        <div className={styles.text}>
          {
            texts.map((piece, index) => (piece.is_url
              ? <a key={index} href={piece.text} target='_blank' rel='noopener noreferrer nofollow'>{piece.text}</a>
              : piece.text
            ))
          }
        </div>
        <div className={`${styles.ipDateTime} d-sm-none text-right`}>
          {
            comment.ip && <span>{comment.ip}</span>
          }
        </div>
      </div>
    </div>
  )
}

const AuthorResponse = ({ content }) => (
  <div className={styles.authorResponse}>{content}</div>
)

export { Statisticbar, Comment, AuthorResponse }
