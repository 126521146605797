
import React from 'react'
import debounce from 'lodash.debounce'

class InfiniteScroll extends React.Component {
  constructor (props) {
    super(props)
    this.state = { offsetHeight: 0 }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    this.props.fetchDataOnMount()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    const innerHeight = window.innerHeight
    const scrollTop = document.documentElement.scrollTop ||
                      document.body.scrollTop ||
                      window.pageYOffset
    const offsetHeight = document.documentElement.offsetHeight

    debounce(() => {
      if (offsetHeight < this.state.offsetHeight) {
        this.setState({ offsetHeight: offsetHeight - 200 })
        return
      }

      if ((offsetHeight >= this.state.offsetHeight + 200) &&
          (innerHeight + scrollTop > offsetHeight * 0.9)) {
        this.setState({ offsetHeight: offsetHeight })
        this.props.fetchDataOnScroll()
      }
    }, 300)()
  }

  render () {
    return this.props.children
  }
}

export default InfiniteScroll
